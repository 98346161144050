// import { encode } from '@msgpack/msgpack';
import { UseQueryResult, useQuery } from 'react-query';
import { useBackendApi } from '../lib/backend';
import {
  ExerciseDto,
  ExerciseFlexibilityDto,
  ExerciseScoreDto,
  ConfigurableExercise,
} from './backend-client/data-contracts';
import { unwrapResponse } from './util';
import { naiveFetchJSON } from './http';
import { useMemo } from 'react';

export interface PoseClassifierData {
  classifierID: string;
  classifierType: string;
  forest: any;
}

export interface ExerciseDetails {
  exercise: ExerciseDto;
  configuration: ConfigurableExercise;
  flexibility: ExerciseFlexibilityDto | null;
  score: ExerciseScoreDto | null;
  classifier: PoseClassifierData;
}

export function fetchExercise(exerciseId: string) {
  return naiveFetchJSON<ExerciseDetails>(`/movements/${exerciseId}.json`);
}

export function useExerciseDetails(exerciseIds: string[] | undefined) {
  return useQuery(['exercise', ...(exerciseIds || [])], {
    queryFn: async () => Promise.all(exerciseIds!.map(fetchExercise)),
    enabled: !!exerciseIds,
  });
}

export function useExerciseDetail(exerciseId: string | undefined) {
  return useQuery(['exercise-detail', exerciseId], {
    queryFn: () => fetchExercise(exerciseId!),
    enabled: !!exerciseId,
  });
}

export function useExerciseSummary(exerciseId: string | undefined) {
  const exerciseMapQ = useExerciseMap();

  const data = exerciseId && exerciseMapQ.data?.[exerciseId];

  return {
    ...exerciseMapQ,
    data,
  } as UseQueryResult<ExerciseSummary | undefined>;
}

export type ExerciseSummary = Pick<
  ExerciseDto,
  'id' | 'display_name' | 'exercise_description' | 'exercise_video_link'
>;

export function useExerciseList() {
  return useQuery(['exercise-summary'], {
    queryFn: () =>
      naiveFetchJSON<ExerciseSummary[]>('/movements/movement-list.json'),
  });
}

export function useExerciseMap() {
  const exercisesQ = useExerciseList();

  const data = useMemo(() => {
    const map: Record<string, ExerciseSummary> = {};
    for (const summary of exercisesQ.data || []) {
      map[summary.id] = summary;
    }
    return map;
  }, [exercisesQ.data]);

  return {
    ...exercisesQ,
    data,
  } as UseQueryResult<Record<string, ExerciseSummary>>;
}

// Unused?
export function useExerciseMaxRomDistribution(exerciseId: string) {
  const api = useBackendApi();

  return useQuery(['exercise', exerciseId], {
    queryFn: () =>
      api
        .exerciseControllerGetExerciseMaxRomDistribution(exerciseId)
        .then(unwrapResponse),
  });
}

export function useExerciseMaxRomDistributions(exerciseIds: string[]) {
  const api = useBackendApi();

  return useQuery(['exercise', exerciseIds], {
    queryFn: () =>
      api
        .exerciseControllerGetExerciseMaxRomDistributions({
          exercise_ids: exerciseIds,
        })
        .then(unwrapResponse),
  });
}
